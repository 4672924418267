var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.submitted)?_c('b-card',{staticClass:"text-center",attrs:{"bg-variant":"Success"}},[_c('b-button',{attrs:{"variant":"outline-primary","to":{ name: 'crm-utenti-index' }}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CornerUpLeftIcon","size":"16"}}),_vm._v("Lista Utenti ")],1)],1):_vm._e(),(_vm.errore_caricamento)?_c('b-card',{attrs:{"border-variant":"danger"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"8"}},[_c('h4',{staticClass:"mt-0 mb-0 text-danger"},[_vm._v("Errore durante il caricamento delle informazioni iniziali")]),_c('p',[_vm._v("Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie")])]),_c('b-col',{staticClass:"text-center",attrs:{"md":"4"}},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'crm-utenti-index' }}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"CornerUpLeftIcon","size":"16"}}),_vm._v("Lista Utenti ")],1)],1)],1)],1):_vm._e(),(_vm.Caricato == false)?_c('div',{staticClass:"text-center my-3"},[_c('b-spinner',{staticClass:"mr-1",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary","label":"loading"}})],1):_vm._e(),(_vm.Caricato)?_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Dettagli")])]},proxy:true}],null,false,2106675683)},[_c('b-card',{attrs:{"bg-variant":"Default"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Nome *","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.user.name),callback:function ($$v) {_vm.$set(_vm.campiform.user, "name", $$v)},expression:"campiform.user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3625497125)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Cognome *","label-for":"Surname"}},[_c('validation-provider',{attrs:{"name":"Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.user.surname),callback:function ($$v) {_vm.$set(_vm.campiform.user, "surname", $$v)},expression:"campiform.user.surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,157517585)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email dell'utente","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.campiform.user.email),callback:function ($$v) {_vm.$set(_vm.campiform.user, "email", $$v)},expression:"campiform.user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2840911950)})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_vm._v("   ")]),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Telefono personale","label-for":"telefono"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.user.phone),callback:function ($$v) {_vm.$set(_vm.campiform.user, "phone", $$v)},expression:"campiform.user.phone"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Cellulare","label-for":"Cellulare"}},[_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.campiform.user.mobile_phone),callback:function ($$v) {_vm.$set(_vm.campiform.user, "mobile_phone", $$v)},expression:"campiform.user.mobile_phone"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Sesso *","label-for":"gender_type_id"}},[_c('b-form-select',{attrs:{"options":_vm.options_gender},model:{value:(_vm.campiform.user.gender_type_id),callback:function ($$v) {_vm.$set(_vm.campiform.user, "gender_type_id", $$v)},expression:"campiform.user.gender_type_id"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Ruolo *","label-for":"role_id"}},[_c('validation-provider',{attrs:{"name":"Ruolo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options_role},model:{value:(_vm.campiform.user.role_id),callback:function ($$v) {_vm.$set(_vm.campiform.user, "role_id", $$v)},expression:"campiform.user.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3894884487)})],1)],1)],1)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"MailIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Firma email")])]},proxy:true}],null,false,3513163268)},[_c('b-card',{attrs:{"bg-variant":"Default"}},[_c('validation-observer',{ref:"simpleRules"},[_c('validation-provider',{attrs:{"name":"Firma email *","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.firmaemail.firma),callback:function ($$v) {_vm.$set(_vm.firmaemail, "firma", $$v)},expression:"firmaemail.firma"}},[_c('div',{attrs:{"slot":"toolbar","id":"toolbar"},slot:"toolbar"},[_c('button',{staticClass:"ql-bold"},[_vm._v("Bold")]),_c('button',{staticClass:"ql-italic"},[_vm._v("Italic")]),_c('button',{staticClass:"ql-underline"},[_vm._v("Underline")]),_c('button',{staticClass:"ql-strike mr-2"},[_vm._v("Strike")]),_c('select',{staticClass:"ql-header"},[_c('option',{attrs:{"value":"1"}}),_c('option',{attrs:{"value":"2"}}),_c('option',{attrs:{"value":"3"}}),_c('option',{attrs:{"value":"4"}}),_c('option',{attrs:{"value":"4"}}),_c('option',{attrs:{"value":"5"}}),_c('option',{attrs:{"value":"6"}}),_c('option',{attrs:{"value":"","selected":""}})]),_c('select',{staticClass:"ql-size mr-2"},[_c('option',{attrs:{"value":"small"}}),_c('option',{attrs:{"selected":""}}),_c('option',{attrs:{"value":"large"}}),_c('option',{attrs:{"value":"huge"}})]),_c('button',{staticClass:"ql-list",attrs:{"value":"ordered"}},[_vm._v("list ordered")]),_c('button',{staticClass:"ql-list",attrs:{"value":"bullet"}},[_vm._v("list bullet")]),_c('select',{staticClass:"ql-align mr-2"}),_c('button',{staticClass:"ql-link"},[_vm._v("Link")]),_c('button',{staticClass:"ql-image mr-2"},[_vm._v("Image")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2454091003)})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.action_press},on:{"click":function($event){$event.preventDefault();return _vm.validationForm()}}},[(_vm.action_press)?_c('div',[_vm._v("Salvaggio in corso "),_c('b-spinner',{staticClass:"ml-1",attrs:{"small":""}})],1):_c('div',[_vm._v("Salva")])])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }